import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { number, object } from 'yup';

import {
  Country,
  ListResponse,
  LocationWithModulesOptions,
  Module,
  Venue,
} from '../../services/types';
import { getLink } from '../../utils/location';
import { FormProps } from '../forms/planAVisit/planAVisitVenueInfo';

import { LocationSelect } from './locationSelect';

type Props = {
  initVenue: number;
  country: Country;
  module?: Module;
  slug?: string;
  hash?: string;
  locations: ListResponse<Omit<Venue, 'distance'>, LocationWithModulesOptions>;
  setInitVenue?: Dispatch<SetStateAction<number>>;
  onTrigger?: Dispatch<SetStateAction<boolean>>;
  onLocationSwitch?: Dispatch<SetStateAction<boolean>>;
  locationsOpen?: boolean;
  setLocationsOpen?: Dispatch<SetStateAction<boolean>>;
  actionText?: string;
  btnText?: string;
  showSelect?: boolean;
  selectBg?: string;
  btnClass?: string;
  selectID?: string;
  changeBtnClass?: string;
  wrapperClass?: string;
  canCancel?: boolean;
};
type Form = Pick<FormProps, 'venue'>;
const Schema = object().shape({
  venue: number().required(),
});
export const NearestLocationSwitch: FC<Props> = ({
  initVenue,
  module,
  slug,
  hash,
  locations,
  setInitVenue,
  onTrigger,
  setLocationsOpen,
  locationsOpen,
  actionText = 'Change Location',
  btnText = 'Go',
  selectBg = '',
  btnClass = '',
  changeBtnClass = '',
  selectID = 'venue',
  showSelect = false,
  canCancel = false,
}) => {
  const [options, showOptions] = useState(initVenue === 0 || showSelect);
  const { register, watch, setValue } = useForm<Form>({
    resolver: yupResolver(Schema),
    defaultValues: { venue: initVenue },
  });
  const venue = watch('venue');

  useEffect(() => {
    if (setLocationsOpen) {
      setLocationsOpen(options);
    }
  }, [options, setLocationsOpen]);

  return (
    <>
      <div className={'flex gap-2'}>
        {(options || !locations?.data[venue]) && (
          <label>
            <span className="sr-only">{actionText}</span>
            <LocationSelect
              register={register('venue')}
              className={classNames(
                'block w-full max-w-sm pl-4 pr-10 py-2 mb-0 text-base text-body border border-accent-lilac dark:border-accent-neon focus:outline-none sm:text-sm rounded-md',
                selectBg,
              )}
              valueField="id"
              selectID={selectID}
              data={Object.values(locations.data)}
              initValue={(value): void => {
                if (!venue) {
                  setValue('venue', value as number);
                  if (setInitVenue) {
                    setInitVenue(value as number);
                  }
                  showOptions(showSelect);
                }
              }}
            />
          </label>
        )}
        {options && locations?.data[venue] && (
          <button
            className={classNames('btn sm rounded-md items-center', btnClass)}
            title={`View information for ${locations.data[venue]?.name}`}
            onClick={(): void => {
              showOptions(showSelect);
              window.location.href = getLink(
                `${slug ?? module}`,
                locations.data[venue]?.country,
                locations.data[venue]?.alias,
                hash,
              );
            }}>
            {btnText}
          </button>
        )}
        {options && venue === 0 && (
          <button
            disabled={true}
            className={classNames('btn sm rounded-md items-center', btnClass)}
            title={`Choose a location to view ${module}`}>
            {btnText}
          </button>
        )}
        {!options && locations?.data[venue] && (
          <button
            className={classNames('btn', changeBtnClass)}
            onClick={(): void => {
              showOptions(true);
              if (typeof onTrigger == 'function') {
                onTrigger(true);
              }
            }}>
            {actionText}
          </button>
        )}
      </div>
      {canCancel && locationsOpen && (
        <button
          className={`text-xs mt-2 uppercase ${btnClass == 'white' ? 'link-white' : ''}`}
          onClick={() => showOptions(false)}>
          <a>Cancel</a>
        </button>
      )}
    </>
  );
};
