import { useRouter } from 'next/router';
import { FC, useEffect, useRef } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { useLocationContext } from '../../providers/locationProvider';
import { useUserContext } from '../../providers/userProvider';
import { SelectVenue } from '../../services/types';

type Props = {
  register: UseFormRegisterReturn;
  className?: string;
  selectID?: string;
  selectValue?: string;
  valueField?: 'id' | 'name' | 'name_short' | 'alias' | 'salesforceid';
  data: SelectVenue[];
  initValue?: (value: string | number) => void;
  init?: number;
  excludeVenues?: string[];
  showCountry?: boolean;
};

export const LocationSelect: FC<Props> = ({
  data,
  register,
  className,
  selectID = 'venue',
  selectValue = '0',
  valueField = 'alias',
  initValue,
  init,
  excludeVenues = [],
  showCountry = true,
}) => {
  const { location } = useLocationContext();
  const { preferredLocation, lastSelectedLocation } = useUserContext();
  const loaded = useRef(false);
  const route = useRouter();
  const defaultLocationId = init
    ? init
    : location
      ? location.id
      : preferredLocation
        ? preferredLocation
        : lastSelectedLocation
          ? lastSelectedLocation
          : undefined;

  useEffect(() => {
    const defaultLocation = data.find((value) => {
      return value.id === defaultLocationId;
    });

    if (!loaded.current && defaultLocation) {
      if (typeof initValue === 'function') {
        initValue(defaultLocation[valueField]);
      }
      loaded.current = true;
    }
  }, [data, defaultLocationId, valueField, initValue, lastSelectedLocation]);
  const sorted: SelectVenue[] = [...data];
  sorted.sort((a, b) => {
    const aCountry = a.country === route.query.country ? 0 : 1;
    const bCountry = b.country === route.query.country ? 0 : 1;
    return aCountry + a.state + a.name > bCountry + b.state + b.name ? 1 : -1;
  });

  const filteredData = sorted.filter((location) => !excludeVenues.includes(location.alias));

  return (
    <span className="select-container relative">
      <select {...register} className={className} id={selectID}>
        <option value={selectValue}>Select Venue</option>

        {filteredData.map((item) => {
          return (
            <option key={item.id} value={item[valueField]}>
              {item.state} - {item.name}
              {item.country !== route.query.country &&
                showCountry &&
                ` - ${item.country.toUpperCase()}`}
            </option>
          );
        })}
      </select>
    </span>
  );
};
