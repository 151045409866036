import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';

import { ContentBlock } from '../../components/elements/content';
import { Img, Source } from '../../components/elements/img';
import { LogoShieldCPA } from '../../components/images/logoShieldCPA';
import { LocationLink } from '../../components/locationLink';
import { Country } from '../../services/types';

type ContentProps = {
  country: Country;
  children?: React.ReactNode;
};

const UKIndex: FC<ContentProps> = ({ country, children }) => {
  const imageArray = [
    'uploads/images/lifestyle/uk/2024/global-homepage/1-tg-web-day-ya.jpg',
    'uploads/images/lifestyle/uk/2024/global-homepage/2-tg-web-day-ya-tab.jpg',
    'uploads/images/lifestyle/uk/2024/global-homepage/3-tg-web-day-junior-aca.jpg',
    'uploads/images/lifestyle/uk/2024/global-homepage/4-tg-web-night-ya.jpg',
    'uploads/images/lifestyle/uk/2024/global-homepage/5-tg-web-terr1.jpg',
    'uploads/images/lifestyle/uk/2024/global-homepage/6-tg-web-day-fam.jpg',
    'uploads/images/lifestyle/uk/2024/global-homepage/7-tg-web-day-coach.jpg',
    'uploads/images/lifestyle/uk/2024/global-homepage/8-tg-web-ya-res.jpg',
    'uploads/images/lifestyle/uk/2024/global-homepage/9-tg-web-ya-bar.jpg',
  ];
  const [count, setCount] = useState(0);
  useEffect(() => {
    const timerId = setInterval(() => {
      setCount((count) => count + 1);
    }, 1800);

    return (): void => clearInterval(timerId);
  }, []);
  const imageStep = imageArray[count % imageArray.length];
  return (
    <>
      <div className="video-wrapper cpa flex flex-col justify-end items-start relative has-dots">
        <div className="mx-0 xs:mx-auto md:mb-[20vh] md:mx-0 relative z-10">
          <div className="flex md:w-full md:max-w-xl flex-col items-start justify-center md:bg-gradient-to-r from-brand via-brand bg-brand-bright md:bg-brand md:bg-opacity-80 text-white pl-16 pr-5 py-4 md:py-10 md:pl-16 md:pr-6 ml-4 -mb-4 md:m-0">
            <h1 className="font-shield-light-condensed text-2xl sm:text-6xl md:text-3 leading-1 tracking-wide md:tracking-normal">
              It’s <span className="text-accent-neon">Golf.</span> It’s{' '}
              <span className="text-accent-yellow">Not Golf.</span>{' '}
              <span className="block font-shield-semibold-condensed text-9xl sm:text-4 md:text-6 leading-none">
                It’s Topgolf.
              </span>
            </h1>
            <div className="block w-20 h-20 sm:w-28 sm:h-28 -rotate-6 md:hidden absolute top-[9px] sm:top-2 -left-[5px] sm:-left-9 z-0">
              <LogoShieldCPA />
            </div>
          </div>
          <div className="hidden md:flex w-full max-w-[560px] 2xl:max-w-[620px] flex-col items-start justify-center bg-gradient-to-r from-brand-bright via-brand-bright bg-brand-bright bg-opacity-80 text-white py-6 ml-28">
            <div className="flex flex-wrap items-center justify-center w-full gap-4 px-2">
              <div className="flex flex-col items-center gap-2.5 leading-none font-shield-black uppercase text-2xl relative">
                <div className="absolute -top-11 -left-3 whitespace-nowrap text-3xl -rotate-3 font-shield-semibold-condensed px-2.5 pt-1 pb-1.5 bg-accent-cyan text-brand leading-none">
                  Plan Your Visit
                </div>
                <div className="flex items-center gap-2">
                  <span className="font-teeline-bold-italic normal-case text-sm">
                    Find your way to come play
                  </span>
                  <i className="fas fa-arrow-right-long text-accent-cyan text-sm" />
                </div>
              </div>
              <div className="flex flex-row items-center gap-4">
                <div className="whitespace-nowrap">
                  <LocationLink slug="plan-a-visit" country={country}>
                    <a
                      className="btn sm white border-2 border-white hover:!bg-brand hover:!border-brand"
                      id="home-hero-btn-book-desktop">
                      Book a Bay
                    </a>
                  </LocationLink>
                </div>
                <div className="whitespace-nowrap">
                  <LocationLink slug="plan-an-event" country={country}>
                    <a
                      className="btn sm cyan outline !bg-brand-bright hover:!bg-brand hover:!border-brand"
                      id="home-hero-btn-plan-desktop">
                      Plan a Party
                    </a>
                  </LocationLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-img absolute overflow-hidden inset-0 lg:right-8 lg:top-8 lg:bottom-8 lg:left-16 bg-brand-navy">
          {imageArray.map((image, index) => (
            <Img
              key={index}
              alt="Groups playing and eating at Topgolf"
              src={image}
              mobile={image}
              className={classNames(
                'w-full h-full object-cover object-4/5-center md:object-center',
                {
                  hidden: image != imageStep,
                },
              )}
              loading={'eager'}
              width={1600}
              height={900}
              aria-hidden={image == imageStep ? false : true}
            />
          ))}
        </div>
        <div className="bgcolor hidden lg:block absolute right-0 top-0 -bottom-6 w-64 bg-brand  dark:bg-brand-navy-light -z-10" />
      </div>
      <div className="relative md:hidden has-dots right pb-12 overflow-hidden">
        <div className="relative px-2 pt-8 pb-6">
          <div className="flex justify-center items-center gap-4 relative z-10">
            <div className="flex items-center gap-2">
              <div className="-rotate-4 text-white uppercase font-shield-semibold-condensed leading-none text-lg text-center">
                Plan
                <br />a Visit
              </div>
              <i className="fas fa-arrow-right text-sm text-accent-cyan" />
            </div>
            <div className="flex items-center gap-4">
              <LocationLink slug={'plan-a-visit'} country={country}>
                <a className="btn white px-4 py-2 whitespace-nowrap" id="home-hero-btn-book">
                  Book a Bay
                </a>
              </LocationLink>
              <LocationLink slug={'plan-an-event'} country={country}>
                <a
                  className="btn outline !bg-brand cyan px-4 py-2 whitespace-nowrap"
                  id="home-hero-btn-plan">
                  Plan a Party
                </a>
              </LocationLink>
            </div>
          </div>
          <Img
            alt="Aerial view of Topgolf targets"
            src="uploads/images/cp/tg-us-mural-white-trans-UL-1280.png?resize.width=600&resize.height=400&resize.method=cover"
            wrapperClass="block absolute inset-0 bg-brand z-0"
            className="w-full h-full object-cover object-bottom opacity-20"
            width={600}
            height={400}
          />
        </div>
      </div>
      {children}
      <ContentBlock hasDots hasDotsRight padtop={false}>
        <div className="intro mt-12 md:mt-8 lg:mt-16 px-4 py-8 md:p-8 lg:px-12 lg:py-16 xl:py-20 relative bg-white-blue dark:bg-brand-navy-light dark:lg:bg-brand-navy dark:lg:border-2 dark:lg:border-gray">
          <div className="relative md:w-3/5 md:grid md:grid-cols-2 gap-x-6 gap-y-8 z-20">
            <h2 className="font-shield-light-condensed -ml-6 mt-1.5 mb-6 md:-ml-[72px] md:-mt-5 md:mb-0 md:col-span-2 text-7xl lg:text-9xl text-white">
              <span className="inline-block px-5 md:px-6 pt-2 pb-2.5 bg-brand leading-none">
                100% <span className="text-accent-neon">Golf.</span>
              </span>{' '}
              <span className="block ml-6 -mt-4 md:-mt-7 md:ml-24 -rotate-3">
                <span className="inline-block px-5 md:px-6 pt-2 pb-2.5 bg-brand-bright font-shield-semibold-condensed leading-none mt-2">
                  100% <span className="text-accent-yellow">Not Golf.</span>
                </span>
              </span>
            </h2>
            <p className="md:mb-0">
              We’ve got lots of stuff that makes us{' '}
              <span className="font-teeline-bold-italic dark:text-accent-neon">golf:</span> clubs,
              balls, tees, turf, a ball-picker-upper-cart thing, etc. We also have lots of stuff
              that’s{' '}
              <span className="font-teeline-bold-italic dark:text-accent-yellow whitespace-nowrap">
                not golf:
              </span>{' '}
              loud music, giant targets, giant TVs, and hand-crafted food & drinks.
            </p>
            <p className="mb-0">
              Put all these things under one roof, and you’ve got yourself a good time. So consider
              this your formal invitation to grab the fam, a few friends, or some coworkers and{' '}
              <span className="font-teeline-bold-italic">Come Play Around.</span>
            </p>
          </div>
          <div className="absolute -top-6 -right-4 md:top-1/2 md:-right-16 md:-translate-y-1/2 w-3/5 h-36 md:w-2/5 md:h-3/4 z-10 md:shadow-lg lg:shadow-xl">
            <Img
              alt="Playing around at Topgolf"
              src="uploads/images/cp/day/tg-cpa-bay-day-adult-group-iRjQJcTx-2.jpg"
              className="w-full h-full object-cover object-right-top md:object-4/5-1/8"
              width={1280}
              height={720}>
              <Source
                srcSet={'uploads/images/cp/day/tg-cpa-bay-day-adult-group-iRjQJcTx-2.jpg'}
                width={480}
                media="(max-width: 768px)"
              />
            </Img>
          </div>
          <div className="absolute inset-0 z-0" aria-hidden={true}>
            <Img
              alt="Topgolf mural"
              src="uploads/images/cp/tg-us-mural-white-trans-UL-1280.png"
              className="w-full h-full object-cover object-center filter brightness-200 dark:brightness-50 dark:opacity-5"
              width={1200}
              height={900}>
              <Source
                srcSet={'uploads/images/cp/tg-us-mural-white-trans-UL-1280.png'}
                width={480}
                height={640}
                media="(max-width: 768px)"
              />
            </Img>
          </div>
        </div>
        <div className="block absolute bottom-3 md:bottom-auto md:top-8 left-0 md:left-auto md:right-8 w-3/5 h-2/5 md:w-56 md:h-64 bg-gradient-to-b from-[#0406a4] to-brand  dark:bg-brand-navy-light -z-10" />
      </ContentBlock>
      <section className="content relative mt-8">
        <div className="absolute left-0 h-112 md:h-full w-full md:w-2/5 2xl:w-1/3 bg-gradient-to-b from-[#0406a4] to-brand dark:bg-brand-navy-light">
          <div className="absolute inset-0">
            <Img
              alt="Topgolf mural"
              src="uploads/images/cp/tg-us-mural-neon-crop-cart-shield-1280.png"
              className="w-full h-full object-cover object-4/5-center opacity-20 brightness-75"
              width={1280}
              height={960}
              aria-hidden="true">
              <Source
                srcSet={'uploads/images/cp/tg-us-mural-neon-crop-cart-shield-1280.png'}
                width={480}
                height={640}
                media="(max-width: 768px)"
              />
            </Img>
          </div>
        </div>
        <div className="container relative mx-auto px-4 pt-4 pb-8 md:p-8 lg:p-16 overflow-x-hidden">
          <div className="md:grid md:grid-cols-10 2xl:grid-cols-5 items-center">
            <div className="md:col-span-3 2xl:col-span-1 pt-6 pb-8 px-4 md:py-16 md:pl-0 md:pr-12">
              <h2 className="text-7xl md:text-3.5 lg:text-4.5 2xl:text-10xl font-shield-semibold-condensed text-white leading-none has-linebreak has-linebreak-neon">
                How it Works
              </h2>
              <div className="mt-4 text-white 2xl:text-sm drop-shadow-body">
                <p>
                  Here at Topgolf, there’s{' '}
                  <span className="font-teeline-bold-italic">no pressure</span> to have a good golf
                  swing or score a lot of points. It’s all about everyone having fun.
                </p>
                <p>
                  Each of our venues features{' '}
                  <span className="font-teeline-bold-italic">all-weather hitting bays</span> for
                  year-round comfort with HDTVs throughout our sports bar and restaurant. Here’s how
                  to get started:
                </p>
                <LocationLink slug={'experience'} country={country}>
                  <a
                    className="btn sm outline white"
                    id="home-hero-btn-plan"
                    aria-label="Learn more about the Topgolf Experience">
                    Learn More
                  </a>
                </LocationLink>
              </div>
            </div>
            <div className="md:col-span-7 2xl:col-span-4 grid grid-col-1 sm:grid-cols-2 2xl:grid-cols-4 gap-x-4 gap-y-8 items-start">
              <div className="relative flex flex-col bg-white dark:bg-black shadow-lg lg:shadow-xl">
                <div className="overflow-hidden">
                  <Img
                    alt="Guests in a bay at Topgolf"
                    src="uploads/images/cp/day/tg-cpa-bay-day-adult-group-xnHLtL3-rev.jpg?resize.width=800&resize.height=400&resize.method=cover"
                    className="object-cover w-full h-full object-center-1/8"
                    width={800}
                    height={400}
                  />
                </div>
                <div className="flex-1 relative px-4 pb-4 md:px-5 2xl:pt-4">
                  <div className="absolute -top-2 2xl:top-4 left-1/2 transform -translate-x-1/2 -translate-y-full -rotate-4 flex gap-2 items-center justify-center px-3 py-1.5 2xl:pt-1 2xl:pb-1.5 bg-accent-cyan text-brand leading-none uppercase text-3xl md:text-5xl 2xl:text-3xl">
                    <div className="font-shield-light-condensed">Step</div>
                    <div className="font-shield-black">1</div>
                  </div>
                  <div className="text-center -mt-2 2xl:mt-0">
                    <h3 className="whitespace-nowrap inline-block bg-brand text-accent-cyan px-3 py-1.5 text-8xl sm:text-6xl md:text-7xl 2xl:text-5xl leading-none font-shield-semibold-condensed uppercase text-center">
                      Gather Your Crew
                    </h3>
                  </div>
                  <p className="text-sm text-center mt-4 mb-2">
                    Whether you’re a planner or procrastinator, we’ve got options for you. Make a
                    reservation in advance with our{' '}
                    <LocationLink slug={'plan-a-visit'} country={country}>
                      <a className="font-teeline-bold-italic">super simple online booking tool</a>
                    </LocationLink>{' '}
                    or just walk in and set up your tee time.
                  </p>
                </div>
              </div>
              <div className="relative flex flex-col bg-white dark:bg-black shadow-lg lg:shadow-xl">
                <div className="overflow-hidden">
                  <Img
                    alt="Guests checking in at Topgolf"
                    src="uploads/images/cp/playmaker/tg-cpa-playmaker-lobby-checkin-KTp5zGS.jpg?resize.width=800&resize.height=400&resize.method=cover"
                    className="object-cover w-full h-full object-center-1/8"
                    width={800}
                    height={400}
                  />
                </div>
                <div className="flex-1 relative px-4 pb-4 md:px-5 2xl:pt-4">
                  <div className="absolute -top-2 2xl:top-4 left-1/2 transform -translate-x-1/2 -translate-y-full -rotate-4 flex gap-2 items-center justify-center px-3 py-1.5 2xl:pt-1 2xl:pb-1.5 bg-accent-cyan text-brand leading-none uppercase text-3xl md:text-5xl 2xl:text-3xl">
                    <div className="font-shield-light-condensed">Step</div>
                    <div className="font-shield-black">2</div>
                  </div>
                  <div className="text-center -mt-2 2xl:mt-0">
                    <h3 className="inline-block bg-brand text-accent-cyan px-3 py-1.5 text-8xl sm:text-6xl md:text-7xl 2xl:text-5xl leading-none font-shield-semibold-condensed uppercase text-center">
                      Check In
                    </h3>
                  </div>
                  <p className="text-sm text-center mt-4 mb-2">
                    Upon arrival, check in with our front desk team who will find the perfect bay
                    for you. Before or after you play, grab food and drink in our restaurant and
                    bar.
                  </p>
                </div>
              </div>
              <div className="relative flex flex-col bg-white dark:bg-black shadow-lg lg:shadow-xl">
                <div className="overflow-hidden">
                  <Img
                    alt="Guests choosing a Topgolf game"
                    src="uploads/images/cp/playmaker/tg-cpa-playmaker-bay-panel-cb5tTjk.jpg?resize.width=800&resize.height=400&resize.method=cover"
                    className="object-cover w-full h-full object-center-1/8"
                    width={800}
                    height={400}
                  />
                </div>
                <div className="flex-1 relative px-4 pb-4 md:px-5 2xl:pt-4">
                  <div className="absolute -top-2 2xl:top-4 left-1/2 transform -translate-x-1/2 -translate-y-full -rotate-4 flex gap-2 items-center justify-center px-3 py-1.5 2xl:pt-1 2xl:pb-1.5 bg-accent-cyan text-brand leading-none uppercase text-3xl md:text-5xl 2xl:text-3xl">
                    <div className="font-shield-light-condensed">Step</div>
                    <div className="font-shield-black">3</div>
                  </div>
                  <div className="text-center -mt-2 2xl:mt-0">
                    <h3 className="whitespace-nowrap inline-block bg-brand text-accent-cyan px-3 py-1.5 text-8xl sm:text-6xl md:text-7xl 2xl:text-5xl leading-none font-shield-semibold-condensed uppercase text-center">
                      Pick Your Game
                    </h3>
                  </div>
                  <p className="text-sm text-center mt-4 mb-2">
                    Settle into your all-weather hitting bay, and a bay host will help you set up
                    your game and profiles for each player. The fun part is deciding which of{' '}
                    <LocationLink slug={'play/games/'} country={country}>
                      <a className="font-teeline-bold-italic">our games</a>
                    </LocationLink>{' '}
                    to play first, with options for every skill level.
                  </p>
                </div>
              </div>
              <div className="relative flex flex-col bg-white dark:bg-black shadow-lg lg:shadow-xl">
                <div className="overflow-hidden">
                  <Img
                    alt="Guest celebrating a great shot"
                    src="uploads/images/cp/day/tg-cpa-bay-day-adult-male-mbosjfo.jpg?resize.width=800&resize.height=400&resize.method=cover"
                    className="object-cover w-full h-full object-top"
                    width={800}
                    height={400}
                  />
                </div>
                <div className="flex-1 relative px-4 pb-4 md:px-5 2xl:pt-4">
                  <div className="absolute -top-2 2xl:top-4 left-1/2 transform -translate-x-1/2 -translate-y-full -rotate-4 flex gap-2 items-center justify-center px-3 py-1.5 2xl:pt-1 2xl:pb-1.5 bg-accent-cyan text-brand leading-none uppercase text-3xl md:text-5xl 2xl:text-3xl">
                    <div className="font-shield-light-condensed">Step</div>
                    <div className="font-shield-black">4</div>
                  </div>
                  <div className="text-center -mt-2 2xl:mt-0">
                    <h3 className="inline-block bg-brand text-accent-cyan px-3 py-1.5 text-8xl sm:text-6xl md:text-7xl 2xl:text-5xl leading-none font-shield-semibold-condensed uppercase text-center">
                      Smash It
                    </h3>
                  </div>
                  <p className="text-sm text-center mt-4 mb-2">
                    Using our complimentary clubs or your own, take aim at the giant outfield
                    targets and our high-tech balls will score themselves. While you play,{' '}
                    <LocationLink slug={'menu'} country={country}>
                      <a className="font-teeline-bold-italic">order food and drinks</a>
                    </LocationLink>{' '}
                    from your bay.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="">
          <div className="px-4 py-6 sm:px-6 sm:py-8 lg:py-12 lg:px-16 flex flex-col lg:flex-row items-center justify-center flex-wrap mx-auto gap-1 lg:gap-4">
            <div className="flex flex-col md:flex-row items-center justify-center gap-x-4 gap-y-3 text-center lg:text-left leading-none font-shield-black uppercase text-2xl md:text-4xl">
              <span className="block text-10xl lg:text-3 -rotate-3 font-shield-semibold-condensed px-3 pt-1 pb-1.5 bg-accent-neon text-brand leading-none">
                Ready to play?
              </span>
              <span className="font-shield block md:inline normal-case">
                Start planning your visit:
              </span>
            </div>
            <div className="flex items-center gap-4 mt-4 mb-2 lg:m-0">
              <div className="inline-flex whitespace-nowrap">
                <LocationLink slug={'plan-a-visit'} country={country}>
                  <a className="btn sm" id="home-hiw-btn-book">
                    Book a Bay
                  </a>
                </LocationLink>
              </div>
              <div className="inline-flex whitespace-nowrap">
                <LocationLink slug={'plan-an-event'} country={country}>
                  <a className="btn sm outline" id="home-hiw-btn-plan">
                    Plan a Party
                  </a>
                </LocationLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UKIndex;
