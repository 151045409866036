import { GetStaticPaths, NextPage } from 'next';
import Head from 'next/head';
import Script from 'next/script';

import { ContentBlock } from '../../components/elements/content';
import { Img, Source } from '../../components/elements/img';
import { PromoItem } from '../../components/elements/promo';
import { LocationLink } from '../../components/locationLink';
import { Layout } from '../../components/template/layout';
import USAIndex from '../../content/index';
import UKIndex from '../../content/uk';
import { useUserContext } from '../../providers/userProvider';
import { ImgProxyResponse, getImgProxyURL } from '../../services/imgproxy';
import { buildLocationsWithModules } from '../../services/locations';
import { getCountryStaticPaths } from '../../services/paths';
import { buildCountryPromos, getCountryPromos } from '../../services/promos';
import {
  Await,
  Country,
  CountryStaticProps,
  ListResponse,
  LocationWithModulesOptions,
  VenueWithModules,
} from '../../services/types';
import { assetPrefix, s3 } from '../../utils/env';
import { GetStaticProps } from '../../utils/nextTypes';

export type FoldImagesType = {
  day: {
    url: ImgProxyResponse | null;
    media: string | null;
  };
  night: {
    url: ImgProxyResponse | null;
    media: string | null;
  };
};

type PageProps = {
  title: string;
  country: Country;
  locations: ListResponse<VenueWithModules<['planvisit']>, LocationWithModulesOptions>;
  promos: Await<ReturnType<typeof getCountryPromos>>;
  foldImage: FoldImagesType;
};

const CountryPage: NextPage<PageProps> = ({ title, country, locations, promos, foldImage }) => {
  const { lastSelectedLocation, preferredLocation } = useUserContext();
  const locationId = preferredLocation
    ? preferredLocation
    : lastSelectedLocation
      ? lastSelectedLocation
      : 0;
  return (
    <Layout
      title={title}
      country={country}
      showNotification={false}
      description="Come Play Around at one of our premier entertainment venues featuring fun golf games for all skill levels, a full-service bar and restaurant, all-weather hitting bays and private event spaces.">
      <Head>
        <link rel="alternate" href="https://topgolf.com/us/" hrefLang="en-us" />
        <link rel="alternate" href="https://topgolf.com/uk/" hrefLang="en-gb" />
        <script type="application/ld+json">
          {`
            {
             "@context": "https://schema.org",
             "@type": "Organization",
             "name": "Topgolf",
             "url" : "https://topgolf.com"
            }
          `}
        </script>
      </Head>
      {country == 'uk' ? (
        <>
          <Script src={assetPrefix + 'js/optanon2023.uk.js'} id="optanonUK" />
          <Script
            src={assetPrefix + 'js/INA_UK_Topgolf_Homepage.js'}
            id="INA_UK_Topgolf_Homepage"
          />
          <UKIndex country={'uk'}>
            <section className="content">
              <div className="container relative p-4 pt-0 mx-auto -mt-2 container--bleed-right md:mt-8 lg:mt-3 md:px-8 md:pb-8 lg:px-16 lg:pb-0">
                <h2 className="text-brand dark:text-accent-cyan text-6xl md:text-3.5 xl:text-3 2xl:text-4 font-shield-semibold-condensed uppercase leading-none">
                  What’s Happening
                </h2>
                <div className="flex gap-4 mt-2 -ml-2 overflow-auto md:mt-4 flex-nowrap md:grid md:grid-cols-3">
                  {promos?.data
                    ?.filter((promo) => !promo.hide_global)
                    .slice(0, 3)
                    .map((promo) => (
                      <PromoItem
                        {...promo}
                        key={promo.id}
                        country={country}
                        wrapClass="promo-item flex flex-col bg-white-blue dark:bg-brand-navy-light my-2 ml-2 border-none"
                      />
                    ))}
                </div>
                <LocationLink slug={'promo'} country={country}>
                  <a
                    className="mt-4 btn outline sm"
                    aria-label="All promos and specials at Topgolf">
                    View All Promos
                  </a>
                </LocationLink>
              </div>
            </section>
          </UKIndex>
        </>
      ) : (
        <>
          <USAIndex
            country={'us'}
            location={locations.data[locationId]}
            locations={locations}
            foldImage={foldImage}
            promos={promos}>
            <section className="content">
              <div className="container relative p-4 pt-0 mx-auto container--bleed-right md:px-8 md:pb-8 lg:px-16 lg:pb-0">
                <div className="flex gap-4 mt-2 -ml-2 overflow-auto md:mt-4 flex-nowrap md:grid md:grid-cols-3">
                  {promos.data
                    .filter(
                      (promo) =>
                        !promo.hide_global &&
                        promo.promo_feature !== 'feature_a' &&
                        promo.promo_feature !== 'feature_b',
                    )
                    .slice(0, 3)
                    .map((promo) => (
                      <PromoItem
                        {...promo}
                        key={promo.id}
                        country={country}
                        wrapClass="promo-item flex flex-col bg-white-blue dark:bg-brand-navy-light my-2 ml-2 border-none"
                      />
                    ))}
                </div>
                <LocationLink slug={'promo'} country={country}>
                  <a
                    className="mt-4 btn outline sm"
                    aria-label="All promos and specials at Topgolf">
                    View All Promos
                  </a>
                </LocationLink>
              </div>
            </section>
          </USAIndex>
        </>
      )}
      <ContentBlock hasDots={true} hasDotsTopLg={true} hasDotsRight={true}>
        <div className="relative lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="max-w-full lg:col-span-9 lg:row-span-full">
            <div className="-mx-4 md:-mx-8 lg:m-0">
              {country == 'uk' ? (
                <Img
                  alt="Group inside a bay at Topgolf"
                  src="uploads/images/lifestyle/uk/adults/tg-uk-Bay-Tee-Line-Night-Adults-Groups-220.jpg"
                  className="w-full"
                  width={800}
                  height={450}>
                  <Source
                    srcSet={
                      'uploads/images/lifestyle/uk/adults/tg-uk-Bay-Tee-Line-Night-Adults-Groups-220.jpg'
                    }
                    width={1280}
                    media="(min-width: 768px)"
                  />
                </Img>
              ) : (
                <Img
                  alt="Group inside a bay at Topgolf"
                  src="uploads/images/cp/night/tg-cpa-bay-night-adult-group-ZRjz5tL.jpg"
                  className="w-full object-left-2/4"
                  width={800}
                  height={450}>
                  <Source
                    srcSet={'uploads/images/cp/night/tg-cpa-bay-night-adult-group-ZRjz5tL.jpg'}
                    width={1280}
                    media="(min-width: 768px)"
                  />
                </Img>
              )}
            </div>
          </div>
          <div className="z-10 flex max-w-full -mt-12 transform lg:m-0 lg:col-span-6 bg-brand-navy dark:bg-brand-navy-light lg:absolute lg:col-start-7 lg:row-span-full lg:top-1/2 lg:-translate-y-1/2">
            <div className="items-center max-w-full p-6 py-8 md:p-8 lg:py-10 text-white-off">
              <h2 className="mb-4 text-6xl leading-none uppercase md:text-3 font-shield-semibold-condensed">
                Make Your Event{' '}
                <span className="lg:block">
                  More <span className="text-accent-cyan">Eventful</span>
                </span>
              </h2>
              <p className="m-0">
                Rain or shine, day or night, make your next party or group event more eventful at
                Topgolf. We offer catering packages perfect for your next birthday party, company
                event, {country == 'uk' ? '' : 'fundraiser'} or social get-together!
              </p>
              <div className="max-w-full mt-5">
                <LocationLink country={country} slug="plan-an-event">
                  <a className="btn white">Parties &amp; Events</a>
                </LocationLink>
              </div>
            </div>
          </div>
        </div>
      </ContentBlock>
    </Layout>
  );
};

export const getStaticPaths: GetStaticPaths<CountryStaticProps> = async () => {
  return getCountryStaticPaths();
};

export const getStaticProps: GetStaticProps<PageProps, CountryStaticProps> = async ({ params }) => {
  const locations = await buildLocationsWithModules<['planvisit']>({
    limit: 0,
    country: params.country,
    modules: ['planvisit'],
  });
  const promos = await buildCountryPromos({ country: params.country, limit: 20 });
  const foldImage: FoldImagesType = {
    day: {
      url: null,
      media: null,
    },
    night: {
      url: null,
      media: null,
    },
  };
  foldImage.day.url = await getImgProxyURL({
    path: s3 + 'uploads/images/cp/day/tg-cpa-bay-day-adult-group-Ruiz-0892.jpg',
    options: {
      width: 1120,
      height: 840,
      fit: 'fit',
    },
  });
  foldImage.night.url = await getImgProxyURL({
    path: s3 + 'uploads/images/cp/night/tg-cpa-bay-night-adult-group-selects-r.jpg',
    options: {
      width: 1120,
      height: 840,
      fit: 'fit',
    },
  });
  return {
    props: {
      title: `Come Play Around | Golf, Party Venue, Sports Bar & Restaurant`,
      country: params.country,
      locations,
      promos,
      foldImage: foldImage,
    },
  };
};

export default CountryPage;
