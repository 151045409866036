import classNames from 'classnames';
import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const LogoShieldCPA: FC<Props> = ({ className = 'fill-white' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="b" viewBox="0 0 113.0757 108">
      <defs />
      <g id="c">
        <g>
          <path
            className="d fill-brand"
            d="M108.3226,8.4587C91.8017,2.846,74.38,0,56.5417,0h-.0041C38.6979-.0004,21.275,2.8455,4.753,8.4588L0,10.0735l.9781,4.9236c3.4002,17.1161,9.6472,33.6278,18.5675,49.0764,8.9183,15.4486,20.0943,29.1144,33.217,40.6174l3.7749,3.3091,3.7749-3.3089c3.5277-3.092,6.9024-6.3529,10.1428-9.7504,1.5489,2.8853,4.5937,4.8527,8.0913,4.8527,5.0612,0,9.1789-4.1176,9.1789-9.1789,0-4.004-2.5812-7.4081-6.1634-8.6595,4.3411-5.6559,8.3497-11.6136,11.9683-17.8819,8.92-15.4481,15.167-31.9598,18.5672-49.0759l.9781-4.9236-4.753-1.6148Z"
          />
          <g>
            <g>
              <path
                className={classNames('fill-current', className)}
                d="M78.5465,87.1624c-1.9065,0-3.4521,1.5455-3.4521,3.4521s1.5455,3.4521,3.4521,3.4521,3.4521-1.5456,3.4521-3.4521-1.5455-3.4521-3.4521-3.4521Zm0,6.2971c-1.5688,0-2.845-1.2763-2.845-2.8451s1.2762-2.845,2.845-2.845,2.845,1.2763,2.845,2.845-1.2762,2.8451-2.845,2.8451Z"
              />
              <path
                className={classNames('fill-current', className)}
                d="M79.4892,91.1765c.4793,0,.7465-.2213,.7465-.7096v-.5805c0-.4791-.2672-.737-.7465-.737h-2.4967v2.9299h.7093v-1.4283l1.6586,1.4283h.8845l-1.0229-.9029h.2672Zm-1.7874-1.3545h1.7138c.0736,0,.1105,.0458,.1105,.1198v.5067c0,.0831-.0369,.1197-.1105,.1197h-1.7138v-.7462Z"
              />
            </g>
            <g>
              <path
                className={classNames('fill-current', className)}
                d="M56.5376,5.7268c-16.9694-.0004-33.9412,2.718-49.9423,8.1543,3.2928,16.5756,9.4244,32.633,17.9098,47.3287,8.484,14.6964,19.3243,28.0349,32.0325,39.1746,12.7088-11.1397,23.549-24.4782,32.0331-39.1746,8.4854-14.6956,14.617-30.7531,17.9098-47.3287-16.0015-5.4363-32.9733-8.1547-49.9429-8.1543Zm28.5878,49.8005l-.1774-.1577c-.5025-.6321-1.0724-1.2631-1.7063-1.8905-.6187-.6124-1.3017-1.2221-2.0404-1.8278l-7.7213-1.3519c.2798,.3144,.5281,.6254,.738,.9325,.3232,.4724,.5632,.9357,.7176,1.3863,.1613,.4705,.2294,.9269,.2007,1.3649-.0199,.3041-.0935,.5973-.208,.8808l8.2583,4.0807c-.3318,.5686-.6636,1.1373-1.0021,1.7006l-8.4826-4.2716c-.4378,.3342-.9604,.641-1.6025,.9035-.7531,.3078-1.6441,.5605-2.6712,.7478-1.0643,.1942-2.2682,.3171-3.6046,.3589-1.3755,.0428-2.88-.001-4.4998-.1404l5.4291,12.3673c2.4993,.0742,6.2596,.0001,9.2992-.5549-5.7768,8.1907-12.3032,15.721-19.5145,22.5084-9.8417-9.2638-18.4089-19.9109-25.5337-31.7331,2.8689,1.6169,5.7409,2.9014,7.86,3.7589,3.4494,1.3961,7.1503,2.6067,11.0049,3.5786,3.7818,.9535,7.841,1.6827,11.6157,2.0922l-4.1202-12.5182c-1.728-.2847-3.4559-.6515-5.1547-1.0928-1.7292-.4496-3.4093-.9696-5.0134-1.5463-1.5869-.5708-3.0828-1.1909-4.4676-1.8469-1.2674-.6002-2.4231-1.2265-3.4704-1.8656l-11.915,3.0167c-.4281-.7933-.8485-1.5894-1.2616-2.3881l10.6095-2.3998c-.8243-.6565-1.5098-1.31-2.0519-1.9504-.5206-.6158-.9058-1.2163-1.1537-1.7946-.2374-.5535-.3477-1.0848-.3311-1.5879,.0116-.3516,.0864-.6881,.2214-1.0094l-.0053-.0012-12.4042-2.1745c-3.187-7.5838-5.7272-15.3724-7.599-23.3112,13.856-4.1656,28.3539-6.275,43.1703-6.2747,14.8169-.0002,29.3149,2.1091,43.1709,6.2747-3.081,13.0675-7.9751,25.727-14.5831,37.7366Z"
              />
              <path
                className={classNames('fill-current', className)}
                d="M62.2354,48.3307c-.0094,.1485-.0516,.2884-.1321,.4167-.1515,.2417-.4255,.4456-.8127,.604-.3981,.1626-.9138,.2763-1.5339,.3329-.6339,.0576-1.3725,.0546-2.1975-.0165l2.0757,4.7246c2.332,.2278,4.3401,.1964,5.956-.0461,1.5289-.2297,2.6774-.6443,3.4275-1.1952,.7011-.515,1.0472-1.1439,1.0418-1.849-.004-.5313-.2111-1.1058-.6123-1.7088l-7.2123-1.2626Z"
              />
              <path
                className={classNames('fill-current', className)}
                d="M58.8701,47.4858c.0926-.1629,.0846-.3576-.036-.5778-.1202-.2151-.3383-.4346-.6279-.648-.285-.2102-.6405-.415-1.0432-.6045-.1976-.0934-.4075-.1829-.6263-.2678v-.9931c.1611,.0509,.326,.0988,.4834,.1522,.629,.2144,1.2288,.4515,1.7824,.704,.5609,.2562,1.078,.5299,1.5313,.813,.3261,.2038,.6061,.4146,.8609,.6273l6.658,1.1929c-.7083-.5995-1.543-1.1803-2.4744-1.7272-1.0228-.6005-2.1681-1.1707-3.3831-1.6929-1.181-.5079-2.447-.9786-3.7571-1.3961-.5541-.1763-1.1256-.3402-1.7013-.4989v-.8957c.6247,.1675,1.2473,.3382,1.8552,.5227,.7513,.2278,1.4917,.4691,2.2168,.7215,.7324,.2549,1.4526,.5227,2.1563,.8016l2.8593-.6479c-1.0048-.3862-2.0265-.7557-3.0588-1.107-1.0181-.3461-2.0533-.6766-3.0997-.9888-.9612-.287-1.9396-.5586-2.9278-.8166v-13.0956c4.7996-2.5757,10.1496,1.3229,13.5961-5.673-4.6287,1.4261-7.45-2.3912-13.5972-2.4428h-2.8532v25.7261c-.2622-.0317-.5147-.054-.7496-.0622-.4345-.0156-.8206,.0077-1.1397,.0671-.3247,.0606-.5817,.1588-.7499,.2924-.172,.1361-.2519,.3096-.2163,.5179,.0389,.2127,.1956,.4403,.4483,.6699,.257,.2337,.6123,.4686,1.0408,.6902,.4337,.2243,.939,.4334,1.4881,.6128,.5511,.18,1.1416,.3282,1.7405,.43,.5939,.101,1.1348,.146,1.6017,.1411,.461-.0047,.8469-.0583,1.1409-.1535,.2886-.0932,.4874-.2264,.5817-.3931Z"
              />
              <path
                className={classNames('fill-current', className)}
                d="M73.2403,46.4935c-.9855-.5168-1.9961-1.013-3.0214-1.486-.6616-.3052-1.3413-.5916-2.0204-.8775l-2.8471,.7144c.3841,.1753,.7704,.3493,1.1381,.5335,.6724,.3369,1.3235,.6861,1.9464,1.0461,.6403,.3697,1.2536,.7529,1.8315,1.1466,.4689,.3193,.9083,.649,1.329,.9841l7.1959,1.2892c-.8247-.5738-1.6728-1.1305-2.547-1.6592-.9737-.589-1.9793-1.1532-3.0048-1.6911Z"
              />
              <path
                className={classNames('fill-current', className)}
                d="M40.9876,39.9057l-4.7449-2.3885c-1.1177,.0198-2.2195,.0714-3.2919,.1604-1.1612,.0966-2.2959,.2363-3.3871,.4222-1.1576,.1975-2.2736,.4488-3.3252,.7595-.9989,.2951-1.9335,.6552-2.8061,1.0658l11.044,1.9786c.3249-.2628,.6887-.5052,1.1092-.7147,.4911-.2446,1.0433-.4544,1.6439-.6311,.5776-.1699,1.2019-.3098,1.8625-.4209,.6016-.101,1.2374-.1769,1.8957-.2316Z"
              />
              <path
                className={classNames('fill-current', className)}
                d="M44.9899,39.8306c.7313,.0279,1.4825,.0768,2.2496,.146,.7529,.0682,1.524,.1565,2.3101,.2651,.7078,.098,1.4342,.2178,2.1696,.3498l-.5432-1.6501c-1.0576-.2061-2.104-.3935-3.1306-.5534-1.119-.1738-2.226-.3223-3.316-.4445-1.119-.1254-2.2276-.2242-3.3191-.2961-.4524-.0296-.897-.042-1.3444-.0608l4.5118,2.2359c.1382,.0034,.2725,.0027,.4123,.008Z"
              />
              <path
                className={classNames('fill-current', className)}
                d="M44.5579,41.1238c-1.169,.0932-2.2204,.2831-3.089,.5743-.8197,.2751-1.4711,.649-1.9166,1.1154l8.0914,1.4496c.1388-.1575,.314-.3005,.557-.4134,.3265-.1518,.74-.2637,1.2205-.3342,.4695-.0695,1.0052-.0999,1.5902-.0908,.5204,.0081,1.0875,.0524,1.6815,.1251l-.6514-1.9789c-1.3389-.2246-2.6273-.3792-3.8304-.4552-1.308-.083-2.5408-.0805-3.6531,.0081Z"
              />
              <path
                className={classNames('fill-current', className)}
                d="M52.0957,48.4208c-.8073-.2986-1.5457-.63-2.1876-.9783-.6309-.3417-1.1633-.6977-1.5751-1.0524-.2316-.1996-.4195-.398-.5694-.5932l-8.8371-1.5473c-.0085,.6318,.2634,1.3192,.8113,2.0412,.7011,.9239,1.8354,1.8878,3.3499,2.8336,1.5876,.9915,3.554,1.9387,5.7937,2.7677,2.2479,.8322,4.7013,1.5163,7.2117,1.9889l-1.5546-4.7232c-.8508-.206-1.6757-.4532-2.4429-.7368Z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
