import classNames from 'classnames';
import Link from 'next/link';
import { FC } from 'react';

import { Promo, Country } from '../../services/types';
import { LocationLink } from '../locationLink';

import { Img } from './img';

type Props = Promo & {
  key: number;
  location?: string;
  country: Country;
  wrapClass?: string;
};

export const PromoItem: FC<Props> = ({
  pagetitle,
  alias,
  offsite_link,
  list_description,
  image,
  promo_flag,
  promo_flag_bg,
  internal_link,
  country,
  location,
  location_specific,
  wrapClass = 'flex flex-col border-none bg-white-blue dark:bg-brand-navy-light',
}) => {
  const children = (
    <>
      {image && (
        <div className="relative">
          <div className="overflow-hidden shrink-0 aspect-w-16 aspect-h-9" aria-hidden="true">
            <Img src={image} className="object-cover" alt={pagetitle} width={800} height={450} />
          </div>
          {promo_flag && promo_flag !== '' && (
            <div
              className={classNames(
                'absolute right-0 -bottom-2.5 py-1.5 px-3.5 font-shield-bold text-sm uppercase tracking-wide',
                promo_flag_bg,
                { 'bg-brand text-accent-cyan': !promo_flag_bg },
              )}
              aria-hidden="true">
              {promo_flag}
            </div>
          )}
          {offsite_link && offsite_link !== '' && (
            <div
              className="absolute bottom-0 right-0 px-4 py-2 text-sm tracking-wide uppercase bg-brand-navy font-shield-bold text-accent-cyan"
              aria-hidden="true">
              <i className="fas fa-external-link-alt" />
            </div>
          )}
        </div>
      )}
      <div className="relative flex flex-col justify-between flex-1 px-4 py-6 md:p-6">
        <div>
          <div className="flex justify-between mb-4 text-2xl leading-none promo-title font-shield lg:text-4xl">
            <div className="uppercase">{pagetitle}</div>
            <i className="ml-6 fas fa-chevron-right" aria-hidden="true" />
          </div>
          <p className="mb-0 text-sm text-body dark:text-white md:text-base" aria-hidden="true">
            {list_description}
          </p>
        </div>
      </div>
    </>
  );

  if (offsite_link) {
    return (
      <a
        className={wrapClass}
        href={offsite_link}
        target="_blank"
        aria-label={pagetitle}
        rel="noreferrer">
        {children}
      </a>
    );
  }

  if (internal_link) {
    return (
      <Link href={internal_link} className={wrapClass} aria-label={pagetitle}>
        {children}
      </Link>
    );
  }

  return (
    <LocationLink
      slug={'promo/' + alias}
      country={country}
      location={location_specific ? location : undefined}>
      <a className={wrapClass} aria-label={pagetitle}>
        {children}
      </a>
    </LocationLink>
  );
};
