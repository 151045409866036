import Link from 'next/link';
import { FC } from 'react';

import { Promo, Country } from '../../services/types';
import { LocationLink } from '../locationLink';

import { Img } from './img';

type Props = Promo & {
  key: number;
  location?: string;
  country: Country;
  wrapClass?: string;
};

export const PromoSlide: FC<Props> = ({
  pagetitle,
  alias,
  offsite_link,
  image,
  promo_category,
  internal_link,
  country,
  location,
  location_specific,
  wrapClass = 'relative border-0',
}) => {
  const children = (
    <>
      {image && (
        <div className="relative w-full h-full flex flex-col">
          <Img
            alt={pagetitle}
            src={image}
            wrapperClass="relative w-full h-full"
            className="object-cover object-top w-full h-full lg:h-[calc(100%-3rem)] bg-brand"
            width={800}
            height={450}
          />

          <div className="relative lg:absolute bottom-0 left-0 bg-brand-bright px-4 py-3 lg:pl-6 lg:pr-4 lg:py-4 w-[calc(100%-1rem)] lg:w-4/5 max-w-[320px] -mt-4 lg:mt-0">
            {promo_category &&
              ['clubs', 'games', 'leagues', 'lessons'].indexOf(promo_category) !== -1 && (
                <div className="absolute -top-2 sm:-top-3 lg:-top-4 left-2 sm:left-3.5 text-xxs sm:text-xs lg:text-base -rotate-3 font-shield-semibold-condensed uppercase text-center px-1.5 py-0.5 sm:px-2.5 sm:py-1 bg-accent-neon text-brand leading-none inline-block">
                  Golf
                </div>
              )}
            {promo_category &&
              ['app', 'concerts', 'food', 'kids', 'partner', 'special'].indexOf(promo_category) !==
                -1 && (
                <div className="absolute -top-2 sm:-top-3 lg:-top-4 left-2 sm:left-3.5 text-xxs sm:text-xs lg:text-base -rotate-3 font-shield-semibold-condensed uppercase text-center px-1.5 py-0.5 sm:px-2.5 sm:py-1 bg-accent-yellow text-brand leading-none inline-block">
                  Not Golf
                </div>
              )}
            {promo_category &&
              ['events', 'gift_cards', 'pricing'].indexOf(promo_category) !== -1 && (
                <div className="absolute -top-2 sm:-top-3 lg:-top-4 left-2 sm:left-3.5 text-xxs sm:text-xs lg:text-base -rotate-3 font-shield-semibold-condensed uppercase text-center px-1.5 py-0.5 sm:px-2.5 sm:py-1 bg-accent-cyan text-brand leading-none inline-block">
                  Promo
                </div>
              )}
            <div className="flex items-center justify-between gap-3 border-0 lg:gap-6">
              <div className="inline-block text-white font-shield-semibold-condensed uppercase leading-1 text-lg sm:text-5xl">
                {pagetitle}
              </div>
              <div className="hidden sm:block">
                <i className="text-lg fas fa-chevron-right text-accent-cyan" aria-hidden="true" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );

  if (offsite_link) {
    return (
      <a
        className={wrapClass}
        href={offsite_link}
        target="_blank"
        aria-label={pagetitle}
        rel="noreferrer">
        {children}
      </a>
    );
  }

  if (internal_link) {
    return (
      <Link href={internal_link} className={wrapClass} aria-label={pagetitle}>
        {children}
      </Link>
    );
  }

  return (
    <LocationLink
      slug={'promo/' + alias}
      country={country}
      location={location_specific ? location : undefined}>
      <a className={wrapClass} aria-label={pagetitle}>
        {children}
      </a>
    </LocationLink>
  );
};
